//
//
//
//
//
//
//
//
//
//
//






export default {
  components: {  },
    props: {
        
    },
    data: function () {
        return {
            isActive:0,
            list:[
                {id:0,label:'录入',tabName:'lvru'},
                // {id:1,label:'导入',tabName:'daoru'},
            ],
        }
    },
    computed: {

    },
    watch: {

    },

    methods: {
        tabHandle:function(val){
            this.isActive = val.id;
            this.$emit('setTab', val);
        }
    },
    mounted () {

    }
}
