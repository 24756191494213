//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//





import addFile from '@/views/myproject/components/addFile';

export default {
    mixins: [  ],
    components:{addFile},
    data: function(){
        return{
            tableData:[],
            tableHeight:window.innerHeight - 214,
            dialogTitle:'',
            addFileDialog:false,
            rowObj:{},
        }
    }, 
    computed:{
        query:function(){
            return {
                tenderProjectCode:this.getQuery('tenderProjectCode'),
            }
        },
        auditStatus:function(){
            return this.getQuery('auditStatus')
        }
    },
    watch:{
        addFileDialog: function(n, o){
            if(!n){
                this.doSearch();
            }
        },

    },

    methods: {
        doSearch:function(){
            this.$refs.page.queryData();
        },
        reload:function(){
            this.$refs.page.reload();
        },
        //添加文件
        addFile:function(){
            this.dialogTitle = '添加文件';
            this.addFileDialog = true;
        },
        //在线预览
        onlinePreview:function(row){
            if(row.fileName.split('.')[1] == 'docx' || row.fileName.split('.')[1] == 'doc'){
                window.open("https://view.officeapps.live.com/op/view.aspx?src=" + row.fileUrl ,'_blank');
            }else{
                window.open(row.fileUrl,'_blank');
            }
        },
        //删除
        del:function(row){
            let that = this;
            ShowConfirm('删除后无法撤销，请确认是否删除', 'warning', () => {
                that.$delete(that.$store.getters.myprojectUrl + '/tenderFile/'+row.id,{},function(data){
                    ShowMsg('删除成功','success');
                    that.reload();
                })
            });
        },

    },
    mounted() {

    }
}
