//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import tabHandle from '../../components/tabHandle.vue'
export default {

    props: [],
    components: {tabHandle},
    data: function () {
        return {

            form: {
                identity:'',
                bidSectionList: [
                    {
                        // bidSectionCode: "",
                        bidSectionName: "",
                        bidSectionEarnest: "",
                        predictIncome:'',
                        bidOpenTime:'',
                    }
                ]

            },
            activeName: 'lvru',

            tabName: 'lvru',
            imgurl:'',
            platformList:[
                {value:'河北省招标投标公共服务平台（http://www.hebeieb.com）',key:''},
                {value:'惠招标（http://www.hbidding.com）',key:''},
                {value:'易招标招采进宝河北专区（http://hb.zcjb.com.cn/cms/index.htm）',key:''},
                {value:'E招冀成（http://www.hebeibidding.com/tpfront/default.aspx）',key:''},
                {value:'招采云（http://www.zcbidding.com）',key:''},
                {value:'冀招标（http://www.jizhaobiao.com）',key:''},
                {value:'招标通（http://www.hebztb.com）',key:''},
                {value:'中招联合招标采购平台（http://www.365trade.com.cn）',key:''},
                {value:'云采供（http://hbzjk.86ztb.com/index.htm?pf=hbzjk）',key:''},
            ]

        }
    },
    computed: {
        tenderProjectCode:function(){
            return this.getQuery('tenderProjectCode')
        }
    },
    watch: {


    },

    methods: {
        cancle: function () {
            this.goto({
                path:'./list',
            })
        },
        submit: function () {
            let that = this;
            if(!!that.tenderProjectCode){
                that.$put(`${that.getGetters('myprojectUrl')}/tenderProject/${that.tenderProjectCode}`, that.form, function (data) {
                    ShowMsg('修改成功','success');
                    that.goto({
                        path:'./list'
                    })
                }, true)
            }else{
                that.$post(`${that.getGetters('myprojectUrl')}/tenderProject`, that.form, function (data) {
                    ShowMsg('添加成功','success');
                    that.goto({
                        path:'./list'
                    })
                }, true)
            }
        },
        tabHandleClick: function () {

        },
        getTab:function(val){
            this.tabName = val.tabName
        },
        //添加标段
        addBiaoDuan: function () {
            this.form.bidSectionList.push({
                // bidSectionCode: "",
                bidSectionName: "",
                bidSectionEarnest: ""
            })
        },
        //删除标段
        delBiaoDuan: function (index) {
            let that = this
            if (that.form.bidSectionList.length <= 1) {
                ShowMsg('至少保留一个标段', 'error');
                return
            }
            that.$confirm('确认删除此标段？', '提示', {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                callback: function (action) {
                    if (action == 'confirm') {
                        that.form.bidSectionList.splice(index, 1);
                    }
                }
            })
        },
        isNumber:function(ule, value, callback){
            if (!( /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/.test(value))) {
                callback(new Error('只允许输入数字'));
            } else {
                callback()
            }
        },
        
        querySearch(queryString, cb) {
            var newplatformList = this.platformList;
            var results = queryString ? newplatformList.filter(this.createFilter(queryString)) : newplatformList;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (item) => {
                return (item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },

        //预估受益
        forecastMoney:function(val,index){
            if(!!val){
                this.$get(`${this.getGetters('settUrl')}/settShengNei/predictIncome`,{
                    bidSectionName:val
                },function(data){
                    this.form.bidSectionList[index].predictIncome = data.predictIncome ;
                })
            }
        },
        handleChange:function (val) {

        },
    },
    mounted () {
        let that = this;
        if(!!that.tenderProjectCode){
            that.$get(that.$store.getters.myprojectUrl + '/tenderProject/'+that.tenderProjectCode,{},function(data){
                that.form = data
            })
        }
    }
}
