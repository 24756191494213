//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: '',
    props: {
        // projectcode: {
        //     type: String,
        //     default: '',
        // },
        rowObj: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            form: {
                // tenderProjectCode: this.projectcode,
            },
            listStatus: [],
        };
    },
    mounted() {
        this.getZD();
    },
    methods: {
        cancle: function () {
            this.$emit('close');
        },
        submit: function () {
            let that = this;
            this.form.bidSectionCode = this.rowObj.bidSectionCode;
            this.form.bidSectionName = this.rowObj.bidSectionName;

            if (!!this.rowObj.bidSectionCode) {
                that.$post(
                    `${that.getGetters('myprojectUrl')}/bidWinInfo`,
                    that.form,
                    function (data) {
                        ShowMsg('添加成功', 'success');
                        that.cancle();
                    },
                    true
                );
            }
        },

        //获取字典数据
        getZD: function () {
            new Chain()
                .link(function (that, next) {
                    that.$get(`${that.getGetters('comUrl')}/dict/bhlx`, (data) => {
                        that.listStatus = data.filter((item) => {
                            return item.value !== '投标保函';
                        });

                        next();
                    });
                })
                .run(this);
        },
    },
};
