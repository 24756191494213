//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import myInputNumber from './myInputNumber.vue';

export default {
    props: {
        projectcode: {
            type: String,
            default: '',
        },
        rowObj: {
            type: Object,
            default: () => {},
        },
    },
    components: { myInputNumber },
    data: function () {
        return {
            form: {
                // bidSectionCode:this.rowObj.bidSectionCode ? this.rowObj.bidSectionCode : '',
                bidSectionName: this.rowObj.bidSectionName ? this.rowObj.bidSectionName : '',
                bidSectionEarnest: this.rowObj.bidSectionEarnest ? this.rowObj.bidSectionEarnest : undefined,
                tenderProjectCode: this.projectcode,
                bidOpenTime: this.rowObj.bidOpenTime ? this.rowObj.bidOpenTime : '',
            },
        };
    },
    computed: {},
    watch: {},

    methods: {
        cancle: function () {
            this.$emit('close');
        },
        submit: function () {
            let that = this;
            if (!!this.rowObj.bidSectionCode) {
                that.$put(
                    `${that.getGetters('myprojectUrl')}/bidSection/${that.rowObj.bidSectionCode}`,
                    that.form,
                    function (data) {
                        ShowMsg('添加成功', 'success');
                        that.cancle();
                    },
                    true
                );
            } else {
                that.$post(
                    `${that.getGetters('myprojectUrl')}/bidSection`,
                    that.form,
                    function (data) {
                        ShowMsg('添加成功', 'success');
                        that.cancle();
                    },
                    true
                );
            }
        },
    },
    mounted() {},
};
