//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import step from '@/views/myproject/components/step';
import hzbProject from './hzbProject.vue'

export default {
    mixins: [  ],
    components:{step,hzbProject},
    data: function(){
        return{
            pgData:{
                itemStatus:'',
                settStart:'',
                settEnd:'',
            },
            tableData:[],
            tableHeight: window.innerHeight - 381,
            auditStatusList:[
                {value:'待审核',key:'0',type:'info'},
                {value:'已审核',key:'1',type:'success'},
                {value:'未通过',key:'2',type:'danger'},
                {value:'待提交',key:'9',type:'waring'},
            ],
            identityList:[
                {value:'招标人',key:'1',type:''},
                {value:'招标代理',key:'2',type:''},
                {value:'其他',key:'9',type:''},
            ],
            dialogTitle:'惠招标项目拉取',
            pullHzbDialog:false,
        }
    }, 
    computed:{
        isShowPull(){
            return this.$store.state.user.user.hzbAccount ? true : false;
        },
        valuetime:{
            get:function(){
                var startTime = this.pgData.settStart || '';
                var endtime = this.pgData.settEnd || '';
                    if(startTime && endtime){
                        return[
                            startTime,
                            endtime
                        ];
                    }else{
                        return[]
                    }
            },
            set:function(val){
                if(!val){
                    this.pgData.settStart = '' ;
                    this.pgData.settEnd = '';
                }else{
                    var startTime = val[0] || '';
                    var endtime = val[1] || '';
                    this.pgData.settStart = startTime;
                    this.pgData.settEnd = endtime;
                }
            },
        }  
    },
    watch:{

    },

    methods: {
        doSearch:function(){
            this.$refs.page.queryData();
        },
        reload:function(){
            this.$refs.page.reload();
        },
        //添加
        addProject:function(){
            this.goto({
                path:'./addProject'
            })
        },
        //拉取惠泽招项目
        pullHzbProject:function(){
            this.pullHzbDialog = true;
        },
        // 拉取成功
        pullSuccess:function(){
            this.pullHzbDialog = false;
            this.doSearch();
        },
        //编辑
        editProject:function(row){
            this.goto({
                path:'./addProject',
                query:{
                    tenderProjectCode:row.tenderProjectCode
                }
            })
        },
        //删除项目
        delProject:function(row){
            let that = this;
            ShowConfirm('删除后无法撤销，请确认是否删除', 'warning', () => {
                that.$delete(that.$store.getters.myprojectUrl + '/tenderProject/'+row.tenderProjectCode,{},function(data){
                    ShowMsg('删除成功','success');
                    that.reload();
                })
            });
        },
        //标段维护
        bdMaintain:function(row){
            this.goto({
                path:'./sectionList',
                query: {
                    tenderProjectCode: row.tenderProjectCode,
                    auditStatus:row.auditStatus,
                }
            })
        },
        //上传文件
        goUploadFile:function(row){
            this.goto({
                path:'./fileList',
                query: {
                    tenderProjectCode: row.tenderProjectCode,
                    auditStatus:row.auditStatus,
                }
            })
        },

        //提交审核
        submitAudit:function(row){
            this.$confirm('是否确认提交审核？', '提示', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$put(this.$store.getters.myprojectUrl + '/tenderProject/submitAudit/'+ row.tenderProjectCode ,{},function(data){
                    ShowMsg('提交成功','success');
                    this.reload();
                })
            }).catch(() => {

            });
        },

        //过滤字典数据
        filterDiyData:function(arr,val){
            let status =  arr.filter(function(item){
                return item.key == val ;
            })
            if(status.length > 0){
                return status[0]; //返回筛选的对象
            } else{
                return {};
            }
        },

    },
    mounted() {

    }
}
