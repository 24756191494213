//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {

    props: {
        projectcode:{
            type:String,
            default:'',
        },
    },

    components: {},
    data: function () {
        return {
            form: {
                tenderProjectCode:this.projectcode,
                fileName:'',
                fileUrl:'',
                bidSectionCodeList:[],
            },
            tableData:[]
        }
    },
    computed: {

    },
    watch: {


    },

    methods: {
        cancle: function () {
            this.$emit('close');
        },
        submit: function () {
            let that = this;
            if(!that.form.fileUrl){
                return ShowMsg('请上传文件','warning');
            }
            if(that.form.bidSectionCodeList.length == 0){
                return ShowMsg('请至少选择一个标段','warning');
            }
            that.$post(`${this.getGetters('myprojectUrl')}/tenderFile`,that.form,function(data){
                ShowMsg('添加成功', 'success');
                this.cancle();
            },true)

        },
        getfile:function(val){
            this.form.fileName = val.fileName;
            this.form.fileUrl = val.fileUrl;
        },
        handleSelectionChange:function(val){
            var that = this;
            that.form.bidSectionCodeList = [];
            val.forEach(function(item){
                that.form.bidSectionCodeList.push(item.bidSectionCode);
            });
        },  
        getList:function(){
            this.$get(`${this.getGetters('myprojectUrl')}/bidSection/select`, {
                tenderProjectCode:this.projectcode
            }, function (data) {
                this.tableData = data;
            })
        }

    },
    mounted () {
        this.getList();
    }
}
