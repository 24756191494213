//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: '',
    props: {
        // projectcode: {
        //     type: String,
        //     default: '',
        // },
        rowObj: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            form: {
                // tenderProjectCode: this.projectcode,
            },
            blbhStatus: [],
            tableData: [],
            jsztStatusList: [
                { value: '未结算', key: '0', type: 'info' },
                { value: '待审核', key: '1', type: 'info' },
                { value: '审核退回', key: '2', type: 'danger' },
                { value: '待结算', key: '3', type: 'warning' },
                { value: '已结算', key: '4', type: 'primary' },
                { value: '已确认到账', key: '5', type: 'success' },
                { value: '报送异议', key: '6', type: 'danger' },
            ],
        };
    },
    computed: {
        queryData: function () {
            return {
                bidSectionCode: this.rowObj.bidSectionCode,
            };
        },
    },
    mounted() {
        // this.getInfo();
        this.getZD();
    },
    methods: {
        //删除标段
        delSection: function (row) {
            let that = this;
            // return;
            ShowConfirm('删除后无法撤销，请确认是否删除', 'warning', () => {
                that.$delete(
                    that.$store.getters.myprojectUrl + '/bidWinInfo/' + row.id,
                    {},
                    function (data) {
                        ShowMsg('删除成功', 'success');
                        this.$refs.lookpage.queryData();
                    }
                );
            });
        },

        cancle: function () {
            this.$emit('close');
        },

        //获取字典数据
        getZD: function () {
            new Chain()
                .link(function (that, next) {
                    that.$get(`${that.getGetters('comUrl')}/dict/bhlx`, (data) => {
                        that.blbhStatus = data;
                        next();
                    });
                })
                .run(this);
        },

        getInfo() {
            let that = this;
            let queryData = {
                bidSectionCode: this.rowObj.bidSectionCode,
            };

            this.$get(`${that.getGetters('myprojectUrl')}/bidWinInfo/page`, queryData, (data) => {
                this.tableData = data.rows;
            });
        },
    },
};
