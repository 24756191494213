//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import addSection from '@/views/myproject/components/addSection';
import setWinInfo from '@/views/myproject/components/setWinInfo';
import lookWinInfo from '@/views/myproject/components/lookWinInfo';

export default {
    mixins: [  ],
    components:{addSection, setWinInfo,lookWinInfo},
    data: function(){
        return{
            tableData:[],
            tableHeight:window.innerHeight - 214,
            dialogTitle:'',
            addSectionDialog:false,
            setWinInfoDialog:false,
            lookWinInfoDialog:false,
            rowObj:{},
            auditStatusList:[
                {value:'待提交',key:'0',type:'info'},
                {value:'审核中',key:'1',type:'primary'},
                {value:'被退回',key:'2',type:'danger'},
                {value:'待结算',key:'3',type:'warning'},
                {value:'已结算',key:'4',type:'success'},
                {value:'已确认',key:'5',type:'success'},
                {value:'报送异议',key:'6',type:'warning'},
            ]
        }
    }, 
    computed:{
        query:function(){
            return {
                tenderProjectCode:this.getQuery('tenderProjectCode'),
            }
        },
        auditStatus:function(){
            return this.getQuery('auditStatus')
        }
    },
    watch:{
        addSectionDialog: function(n, o){
            if(!n){
                this.doSearch();
            }
        },

    },

    methods: {
        doSearch:function(){
            this.$refs.page.queryData();
        },
        reload:function(){
            this.$refs.page.reload();
        },
        //添加标段
        addSection:function(){
            this.dialogTitle = '添加标段';
            this.rowObj = {};
            this.addSectionDialog = true;
        },
        //修改标段
        edit:function(row){
            this.dialogTitle = '修改标段';
            this.rowObj = clone(row);
            this.addSectionDialog = true;
        },
        //删除标段
        delSection:function(row){
            let that = this;
            ShowConfirm('删除后无法撤销，请确认是否删除', 'warning', () => {
                that.$delete(that.$store.getters.myprojectUrl + '/bidSection/'+ row.bidSectionCode,{},function(data){
                    ShowMsg('删除成功','success');
                    that.reload();
                })
            });
        },
        //设置中标信息
        setWin(row){
            this.rowObj = clone(row);
            this.setWinInfoDialog = true;
        },
        //查看中标信息
        lookWin(row){
            this.rowObj = clone(row);
            this.lookWinInfoDialog = true;
        },
    },
    mounted() {

    }
}
