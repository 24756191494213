//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'myInputNumber',
    props: {
        value: {
            type: [Number, String],
            default: undefined,
        },
        placeholder: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: 'small',
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
