//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: '',
    props: {
        isPullHzbDialog: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hzbPgdata: {
                pagesize: 5,
                pageindex: 1,
                tenderProjectName: '',
            },
            tableData: [],

            currentRow: [], //选中
            loading: false,
        };
    },
    watch: {
        isPullHzbDialog: {
            handler(n, o) {
                if (n) {
                    this.doSearch(1);
                } else {
                    this.clearAll();
                }
            },
            deep: true,
            // immediate:true,
        },
    },
    mounted() {},
    methods: {
        // 指定主键key
        getRowKeys: function (row) {
            return row.tenderProjectCode + row.noticePublishTime || '';
        },

        //具体 选中 ,没有传值就清空
        toggleSelection: function (rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        //发生改变时候
        handleSelectionChange: function (val) {
            this.currentRow = val;
        },

        doSearch: function (val) {
            if (val) {
                this.hzbPgdata.pageindex = 1;
                this.hzbPgdata.pagesize = 5;
            }
            this.$refs.hzbpage.queryData();
        },

        // this.$get(`${this.getGetters('myprojectUrl')}/tenderProject/hzbProjectList`, {
        //         tenderProjectName:'测试'
        //     }, function (data) {
        //         this.tableData = data;
        //     })

        //提交
        submitHandle: function () {
            if (this.currentRow.length == 0) {
                return ShowMsgBox('勾选后再进行提交', 'error');
            }
            this.loading = true;
            let that = this;

            this.$ajax({
                url: `${this.getGetters('myprojectUrl')}/tenderProject/hzbProjectSave`,
                type: 'post',
                data: {
                    hzbProjectList: this.currentRow,
                },
                fztype: true,

                callback: function (data, res) {
                    that.loading = false;
                    ShowMsg('拉取成功', 'success');
                    that.clearAll();
                    that.$emit('success');
                },
                complete: function () {
                    that.loading = false;
                },
            });
        },

        // 清空 选中
        clearAll: function () {
            this.hzbPgdata.tenderProjectName = '';
            this.hzbPgdata.pageindex = 1;
            this.hzbPgdata.pagesize = 5;
            this.toggleSelection();
        },

        //判断是否可选
        handleDisable(row) {
            return row.selEnable;
        },
    },
};
